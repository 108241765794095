<template>
    <div>
        <multiselect
            :placeholder="$t('select')"
            v-model="selected"
            :options="options"
            :class="validateError != '' ? 'box-border-color':''"
            @input="handleInput"
            label="text"
            track-by="value"
            :multiple="false"
            :select-label="''"
            :selected-label="''"
            :deselect-label="''"
            :allow-empty="false"
            :searchable="true">
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
            <span slot="noResult">
                {{ $t('no_result') }}
            </span>
        </multiselect>
        <span class="invalid-feedback-custom"
              v-show="validateError"
              v-html="validateError">
        </span>
    </div>
</template>

<script>
import setSelected from "@/helpers/setSelected";

export default {
    props: {
        value: {
            default: null
        },
        validateError: {
            type: String,
            default: ''
        },
        showEur: {
            default: true
        }
    },
    watch: {
        value: function (newValue) {
            if (this.options.length > 0) {
                this.selected = this.options.filter(c => c.value == newValue);
            }
        },
    },
    data() {
        return {
            selected: this.value,
            options: []
        }
    },
    methods: {
        setSelected: setSelected,
        getOptions() {
            this.options=[
                {value: null,  text: this.$t('select')},
                {value: 'TRY', text:'TRY'},
                {value: 'USD', text:'USD'},
            ];
            if(this.showEur){
                this.options.push({value: 'EUR', text:'EUR'});
            }
            this.selected = this.setSelected(this.value, this.options, false);
        },
        handleInput(event) {
            if (event && event.value) {
                this.$emit('input', event.value);
            }
            else {
                this.$emit('input', null);
            }
        },
        getText(id){
            let found=null
            this.options.forEach((item)=>{
                if(item.value===id){
                    found = item.text;
                }
            });
            return found;
        },
        getItem(id){
            let found=null
            this.options.forEach((item)=>{
                if(item.value===id){
                    found = item;
                }
            });
            return found;
        }
    },
    created() {
        this.getOptions();
    }
};
</script>
